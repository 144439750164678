import { isMobile } from "react-device-detect"
import { imagenes } from "../asset/asset"
import { useContext, useEffect, useState } from "react"
// react icons

import { BiPlay } from "react-icons/bi";
import { BiPause } from "react-icons/bi";

import { Notas} from "../componentes/Notas";
import { MyContext } from "../context/Context";
import { LiveButton } from "../componentes/Reproductor";
import { useNavigate } from "react-router-dom";
import { ButtonBlank } from "../componentes/buttons";

export const Home =()=>{
  return(
    <>
      {isMobile ? <IsMobileHome/> : <IsPcHome  />}
    </>
   
  )
}
const IsMobileHome =()=>{
  return(
    <div className="">
      <HeaderHome />
      <ParkSession />
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h4 className="fs-2 text-white m_font_primary my-5">LEETE ALGO</h4>
       <Notas />
      </div>
    </div>
  )
}
const IsPcHome=()=>{
  return(
    <div className="w-100">
      <HeaderHome />
      <ParkSessionHomePC />
      <div className="d-flex flex-column justify-content-center align-items-center my-5">
        <h4 className="text-white fs-1 my-5 m_font_primary">LEETE ALGO</h4>
        <div className="">
          <Notas />
        </div>
      </div>
    </div>
  )
}
const ParkSession =()=>{
  return(
    <>
      {isMobile ? <ParkSessionHomeMobile /> : <ParkSessionHomePC />}
    </>
  )
}
const ParkSessionHomePC =()=>{
  const navigate = useNavigate();
  return(
    <div className="parkSessionHome">
      <img className="w-100" src={imagenes.parkSessionBgHome} alt="parkSession" />
      <div onClick={()=>navigate("./ParkSession")} className="text-white">
        <ButtonBlank textButton="PARK SESSION"  />
      </div>
    </div>
  )
}
const ParkSessionHomeMobile =()=>{
  const navigate = useNavigate();
  return(
    <div className="parkSessionHome">
      <img className="w-100" src={imagenes.parkSessinBGMobile} alt="" />
      <div onClick={()=>navigate("./ParkSession")} className="text-white">
        <ButtonBlank textButton="PARK SESSION"  />
      </div> 
    </div>
  )
}
const getCurrentMessage = () => {
  const now = new Date();
  const dayOfWeek = now.getDay(); // 0 = Domingo, 1 = Lunes, ..., 6 = Sábado
  const hour = now.getHours();

  if ((dayOfWeek >= 1 && dayOfWeek <= 5) && (hour >= 10 && hour < 12)) {
    return "Demasiado Tarde - Augusto Ochoa";
  }
  if (dayOfWeek === 2 && (hour >= 22 && hour < 24)) {
    return "Un espía en la casa del amor - Martín Emilio Campos";
  }
  if (dayOfWeek === 3 && (hour >= 22 && hour < 24)) {
    return "La isla de los gatos - Rodrigo Artal";
  }
  if (dayOfWeek === 6 && (hour >= 10 && hour < 12)) {
    return "Otro Siglo - Camila Argüello y Catalina Caramuti";
  }
  return "Radio Park - Somos Park";
};
const HeaderHome = () => {
  return(
    <>
      {isMobile ? <HeaderHomeHomeMobile /> : <HeaderHomePC />}
    </>
  )
}
const HeaderHomePC  =()=>{
  const { isPlaying, handlePlayPause } = useContext(MyContext);
  const [programaActual, setProgramaActual] = useState(getCurrentMessage());

  useEffect(() => {
    const updateMessage = () => setProgramaActual(getCurrentMessage());

    // Actualizar el mensaje cada minuto
    const intervalId = setInterval(updateMessage, 60000);

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId);
  }, []);
 
  return(
    <header className="m_h_100vh w-100 d-flex flex-column justify-content-center align-items-center m_header_home">
      <div className="d-flex w-100 ">

        {/* envivo/spectrum/programacion */}
        <div className="w-50 d-flex justify-content-around align-items-center flex-column ">
          <h1 translate="no" className="text-white m_font_primary fst-italic mb-5">PARK</h1>
          <div className=" m_w_40">
            <div className="d-flex mb-2">
              <LiveButton /> 
            </div>
            <div className="m_w_100 m_ctn_programacionActual rounded-4 ">
              <div className="w-100 m_bg_primary rounded-4  d-flex ctn_spectrum">
                <div className="">
                  <div className="play-button p-1 ">
                    <button className="m_btn_playStop rounded-4 border-0" onClick={handlePlayPause}>
                      {isPlaying ? <BiPause size={50} color="white" /> : <BiPlay size={50} color="white" />}
                      </button>
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-center align-items-center">
                  <span className="text-white mx-3">{programaActual}</span>
                </div>
              </div>
              <div className="w-100 d-flex py-2 d-none">
                <span className="text-white mx-3">{programaActual} </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-50 d-flex justify-content-around mt-5 pt-3">
          
          <div className="m_w_40 rounded-2 m_pointer m_banner_parkSession_hover" style={{ position: 'relative' }}>      
            <img className="w-100 rounded-2" src={imagenes.bannerHomeIzquierdo} alt="banner park sesion 20/09" />
            <div className=" w-100 d-flex justify-content-center align-items-center mt-2"> 
              <ButtonBlank textButton="Ver más" linkBlank="https://www.youtube.com/watch?v=ilsyFV6AMEE" />  
            </div>
          </div>
          
          <div className="m_w_40 rounded-2 m_pointer m_banner_parkSession_hover" style={{ position: 'relative' }}>  
            <img className="w-100 rounded-2" src={imagenes.bannerHomeDerecho} alt="banner park sesion 20/09" />
            <div className=" w-100 d-flex justify-content-center align-items-center mt-2"> 
              <ButtonBlank textButton="Ver más" linkBlank="https://www.youtube.com/watch?v=k3JGiWVZoDw&t" />  
            </div>
          </div>

        </div>
      </div> 
    </header>
  )
}
const HeaderHomeHomeMobile  =()=>{
  const { isPlaying, handlePlayPause } = useContext(MyContext);
  const [programaActual, setProgramaActual] = useState(getCurrentMessage());
  useEffect(() => {
    const updateMessage = () => setProgramaActual(getCurrentMessage());

    // Actualizar el mensaje cada minuto
    const intervalId = setInterval(updateMessage, 60000);

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId);
  }, []);
 
  return(
    <header className="m_h_100vh w-100 d-flex flex-column justify-content-center align-items-center m_header_home">
      <div className="d-flex w-100 d-flex flex-column justify-content-center align-items-center">
      <h1 translate="no" className="text-white m_font_primary fst-italic mb-5">PARK</h1>
        {/* envivo/spectrum/programacion */}
        <div className="w-50 d-flex justify-content-around align-items-center flex-column d-none">
          <h1 className="text-white m_font_primary fst-italic mb-5">PARK</h1>
          <div className=" m_w_40">
            <div className="d-flex mb-2">
              <LiveButton /> 
            </div>
            <div className="m_w_100 m_ctn_programacionActual rounded-4">
              <div className="w-100 m_bg_primary rounded-4  d-flex ctn_spectrum">
                <div className="">
                  <div className="play-button p-1 ">
                    <button className="m_btn_playStop rounded-4 border-0" onClick={handlePlayPause}>
                      {isPlaying ? <BiPause size={50} color="white" /> : <BiPlay size={50} color="white" />}
                      </button>
                  </div>
                </div>
                <div className="w-100">
                  <AudioSpectrum  />
                </div>
              </div>
              <div className="w-100 d-flex py-2 ">
                <span className="text-white mx-3">{programaActual}</span>
              </div>
            </div>
          </div>
        </div>
        

        <div className="w-100 d-flex justify-content-around mt-5 pt-3">
          <div className="m_w_40 rounded-2 m_pointer m_banner_parkSession_hover" style={{ position: 'relative' }}>
           <img className="w-100 rounded-2" src={imagenes.bannerHomeIzquierdo} alt="banner park sesion 20/09" />
           <div className=" w-100 d-flex justify-content-center align-items-center mt-2"> 
              <ButtonBlank textButton="Ver más" linkBlank="https://www.youtube.com/watch?v=ilsyFV6AMEE" />  
            </div>
          </div>
          <div className="m_w_40 rounded-2 m_pointer m_banner_parkSession_hover" style={{ position: 'relative' }}>
           <img className="w-100 rounded-2" src={imagenes.bannerHomeDerecho} alt="banner park sesion 20/09" />
           <div className=" w-100 d-flex justify-content-center align-items-center mt-2"> 
              <ButtonBlank textButton="Ver más" linkBlank="https://www.youtube.com/watch?v=k3JGiWVZoDw&t" />  
            </div>
          </div>

        </div>
      </div> 
    </header>
  )
}

const AudioSpectrum = () => {
  const numBars = 50; // Número de barras en el espectro (ajusta según sea necesario)

  return (
    <div className="audio-spectrum">
      <div className="bar-container">
        {Array.from({ length: numBars }, (_, index) => (
          <div
            key={index}
            className="bar"
            style={{ height: `${Math.random() * 100 + 50}px` }} // Randomize initial height
          />
        ))}
      </div>
    </div>
  );
};