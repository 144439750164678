import { CiInstagram } from "react-icons/ci";
import { FaYoutube } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export const Footer =()=>{
  const navigate = useNavigate();
  const añoActual = new Date().getFullYear();
  return(
    <footer className="w-100 bg-dark">
      <div className="d-flex justify-content-center align-items-center py-5 m_slogan_footer">
        <span className="text-white fs-1 m_font_primary">4 AÑOS OBVIANDO ALGORITMOS</span>
      </div>
      <div className="d-flex flex-column align-items-center"> 
        <h5 className="fst-italic fs-1 m_font_primary text-white mb-5 mt-3">PARK</h5>
        <div className="">
          <a className="text-white m_bg_primary m_btn_footer" rel="noopener noreferrer" href="https://www.instagram.com/park.fm" target="_blank"><CiInstagram className="" size={35}/></a> 
          <a className="text-white m_bg_primary m_btn_footer mx-4" rel="noopener noreferrer" href="https://www.youtube.com/@SomosPark" target="_blank"><FaYoutube className="" size={35}/></a>
          <a className="text-white m_bg_primary m_btn_footer" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.app.radiopark&pli=1" target="_blank"><IoLogoGooglePlaystore className="" size={35}/></a>
        </div>
        <div className="mt-5 text-white fs-4 mb-5 pd-5 text-center">
          <span className="">PARK - Una señal en la tierra. Derechos reservados <span onClick={()=>navigate("/login")}>©</span>{añoActual}</span>
        </div>
      </div>

    </footer>
  )
}