// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBfw4t-zdFVwSXvU1jVT2JzuDSkYQw8qbg",
  authDomain: "radiopark-1e4a6.firebaseapp.com",
  databaseURL: "https://radiopark-1e4a6-default-rtdb.firebaseio.com",
  projectId: "radiopark-1e4a6",
  storageBucket: "radiopark-1e4a6.appspot.com",
  messagingSenderId: "947011532604",
  appId: "1:947011532604:web:7d30e091daf4ce3cf45039",
  measurementId: "G-WD1M9XXTC3"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const database = getDatabase(app);

// Función para obtener la URL de la imagen
const getImageUrl = async (idImgNotes) => {
  const imageRef = ref(storage, idImgNotes); // Ajusta la ruta según sea necesario
  try {
    const url = await getDownloadURL(imageRef);
    return url;
  } catch (error) {
    console.error("Error getting image URL: ", error);
    return null;
  }
};
//Enviar nota


export { db, storage, getImageUrl, database };