import { isMobile } from "react-device-detect"
import { Notas } from "../componentes/Notas";

export const NotasPages = () => {
  return (
    <>
      { isMobile ? <IsMobileNotasPage /> : <IsPcNotasPage />}
    </>
  );
}; 
const IsMobileNotasPage = () => {
  return (
    <div className="m-0 p-0">
    <header className="w-100 m_h_100vh m_header_notas_mobile text-white">
      <div className="m_h_100vh w-100 d-flex flex-column justify-content-between align-items-center ">
        <div className="text-start w-100">
          <span className="m_font_primary fs-1 ms-5 fst-italic">-</span>
        </div>
        <div className="text-center">
          <h2 className="m_font_primary mb-3">NOTAS</h2>
          <p className="fs-1 fw-bold fst-italic pt-5">ESCRIBIMOS ROCK</p>
        </div>
        <div className="">

        </div>
      </div>  
    </header> 
    <div className="mt-5">
      <h5 className="text-center m_font_primary text-white fs-2 mb-5">LEETE ALGO</h5>
      <Notas />
    </div>
  </div>
  )
}
const IsPcNotasPage = () => {
  return (
   <div className="w-100 m_notas_page">
    <header className="w-100 m_h_100vh m_header_notas text-white">
        <div className="m_h_100vh w-100 d-flex flex-column justify-content-between align-items-center ">
          <div className="text-start w-100">
            <span className="m_font_primary fs-1 ms-5 fst-italic">-</span>
          </div>
          <div className="text-center">
            <h2 className="m_font_primary mb-5">NOTAS</h2>
            <p className="fs-2 fw-bold fst-italic pt-1">ESCRIBIMOS ROCK</p>
          </div>
          <div className="">
            
          </div>
        </div>  
      </header> 
    <div className="mt-5">
      <Notas />
    </div>
   </div>
  )
}