import parkSessionBgHome from"./parkSessinBG2.jpg";
import psBGbot from "./psBGbot.png";
import bannerQuiebrePS from "./bannerQuiebrePS.jpg";
import bannerQuiebrePsMobile from "./bannerQuiebrePsMobile.jpg";
import bannerQuiebrePStop from "./bannerQuiebrePStop.png";
import parkSessinBGMobile from "./parkSessinBGMobile.png";
import quiebreEntradasParksessionTop from "./decoracion/bannerQuiebrePStop.png"
import quiebreEntradasParksessionBot from "./decoracion/bannerQuiebrePSbot.png"
import bannerHomeIzquierdo from "./BannerHomeInicioIzquierdo.png"
import bannerHomeDerecho from "./BannerHomeInicioIDerecho.jpg"
import BannerPSizquierdo from "./BannerPSizquierdo.jpg"
import BannerPSderecho from "./BannerPSderecho.jpg"
import psNoviembre24 from "./ps/psNoviembre24.jpg"
import psSeptiembre24 from "./ps/psSeptiembre24.jpg"


export const imagenes = {
  bannerQuiebrePsMobile,
  parkSessinBGMobile,
  bannerQuiebrePStop,
  bannerQuiebrePS,
  psBGbot,
  parkSessionBgHome,
  quiebreEntradasParksessionTop,
  quiebreEntradasParksessionBot,
  bannerHomeIzquierdo,
  bannerHomeDerecho,
  BannerPSizquierdo,
  BannerPSderecho,
  psNoviembre24,
  psSeptiembre24,
}