import { isMobile } from "react-device-detect"
import { imagenes} from "../asset/asset"
import { ButtonBlank } from "./buttons"
import { useState } from "react"
import { imgTimeLapse } from "../asset/ps/assetParkSessionTimeLapse"



export const DekstopTimeLapse =()=>{
  const  [completa, setCompleta] = useState(false)
  const  [completa2, setCompleta2] = useState(false)
  const  [mayo24, setMayo24] = useState(false)
  const  [julio24, setJulio24] = useState(false)
  const  [marzo24, setMarzo24] = useState(false)



  const images = Array.from({ length: 21 }, (_, i) => `nov24${String(i + 1).padStart(2, '0')}`);

  return(
    <div className="w-100 bg-black">
          <h3 className="text-white text-center fs-1 m_font_primary fw-bold mt-5">REVIVI LAS PARK SESSION</h3>
        <div className="container bg-lineup">
          
          {/* noviembre 24 */}
          <div className="row my-5 py-5">
            <div className={`d-flex align-items-center justify-content-center ${completa2 ? 'd-none' : 'col-5'}`}><img className="w-75" src={imagenes.psNoviembre24} alt="Entrada Park session noviembre 2024" /></div>
            <div className="col-2 d-flex align-items-center justify-content-center">
              <p className="text-white text-center fs-5 fw-bold fst-italic bg-black py-3">NOVIEMBRE 2024</p>
            </div>
            <div className="col d-flex flex-column align-items-center justify-content-center">
            <div id="carouselExampleControls" className="carousel slide w-100" data-ride="carousel">
            <div className="carousel-inner rounded-1">
              {images.map((image, index) => (
                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={image}>
                  <img className="d-block w-100" src={imgTimeLapse[image]} alt={`Slide ${index + 1}`} />
                </div>
              ))}
            </div>

              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only"></span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span className="sr-only text-white"></span>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
              </button>
            </div>
            <button className="btn m_bg_primary text-white mt-2" onClick={()=>{completa2 ? setCompleta2(false) : setCompleta2(true) }}>{completa2 ? "Contraer" : "Ampliar"}</button>
            </div>
            
          </div>

          {/*septiembre 24*/}
          <div className="row my-5 py-5">         
            <div className="col d-flex flex-column align-items-center justify-content-center affdggs">

            <div id="carouselControls1" className="carousel slide w-100" data-ride="carousel">

            <div className="carousel-inner rounded-1">
              {Array.from({ length: 16 }, (_, i) => `sep24${String(i + 1).padStart(2, '0')}`).map((image, index) => (
                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={image}>
                  <img className="d-block w-100" src={imgTimeLapse[image]} alt={`Slide ${index + 1}`} />
                </div>
              ))}
            </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselControls1" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only"></span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselControls1" data-bs-slide="next">
                <span className="sr-only text-white"></span>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
              </button>
            </div>
            <button className="btn m_bg_primary text-white mt-2" onClick={()=>{completa ? setCompleta(false) : setCompleta(true) }}>{completa ? "Contraer" : "Ampliar"}</button>
            </div>

            <div className={`col-2 d-flex align-items-center justify-content-center${completa ? 'd-none' : ''}`}>
              <p className="text-white text-center fs-5 fw-bold fst-italic bg-black py-3">SEPTIEMBRE 2024</p>
            </div>

            <div className={`col d-flex align-items-center justify-content-center ${completa ? 'd-none' : ''}`}><img className="w-75" src={imagenes.psSeptiembre24} alt="Entrada Park session noviembre 2024" /></div>
          </div>

            {/*julio 24*/}
            <div className="row my-5 py-5">
            <div className={`d-flex align-items-center justify-content-center ${julio24 ? 'd-none' : 'col-5'}`}><img className="w-75" src={imgTimeLapse.psJulio24} alt="Entrada Park session Julio2024" /></div>
            <div className="col-2 d-flex align-items-center justify-content-center">
              <p className="text-white text-center fs-5 fw-bold fst-italic bg-black py-3">JULIO 2024</p>
            </div>
            <div className="col d-flex flex-column align-items-center justify-content-center">
            <div id="carouselControls2" className="carousel slide w-100" data-ride="carousel">
            <div className="carousel-inner rounded-1">
              {Array.from({ length: 19 }, (_, i) => `julio24${String(i + 1).padStart(2, '0')}`).map((image, index) => (
                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={image}>
                  <img className="d-block w-100" src={imgTimeLapse[image]} alt={`Slide ${index + 1}`} />
                </div>
              ))}
            </div>

              <button className="carousel-control-prev" type="button" data-bs-target="#carouselControls2" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only"></span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselControls2" data-bs-slide="next">
                <span className="sr-only text-white"></span>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
              </button>
            </div>
            <button className="btn m_bg_primary text-white mt-2" onClick={()=>{julio24 ? setJulio24(false) : setJulio24(true) }}>{julio24 ? "Contraer" : "Ampliar"}</button> 
            </div>
            
          </div>

              {/*Mayo 24*/}
          <div className="row my-5 py-5">         
            <div className="col d-flex flex-column align-items-center justify-content-center affdggs">

            <div id="carouselControlsMayo" className="carousel slide w-100" data-ride="carousel">

            <div className="carousel-inner rounded-1">
              {Array.from({ length: 21 }, (_, i) => `mayo24${String(i + 1).padStart(2, '0')}`).map((image, index) => (
                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={image}>
                  <img className="d-block w-100" src={imgTimeLapse[image]} alt={`Slide ${index + 1}`} />
                </div>
              ))}
            </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselControlsMayo" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only"></span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselControlsMayo" data-bs-slide="next">
                <span className="sr-only text-white"></span>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
              </button>
            </div>
            <button className="btn m_bg_primary text-white mt-2" onClick={()=>{mayo24 ? setMayo24(false) : setMayo24(true) }}>{mayo24 ? "Contraer" : "Ampliar"}</button>
            </div>

            <div className={`col-2 d-flex align-items-center justify-content-center${mayo24 ? 'd-none' : ''}`}>
              <p className="text-white text-center fs-5 fw-bold fst-italic bg-black py-3">MAYO 2024</p>
            </div>

            <div className={`col d-flex align-items-center justify-content-center ${mayo24 ? 'd-none' : ''}`}><img className="w-75" src={imgTimeLapse.psMayo24} alt="Entrada Park session noviembre 2024" /></div>
          </div>


              {/*Marzo 24*/}
          <div className="row my-5 py-5">
            <div className={`d-flex align-items-center justify-content-center ${marzo24 ? 'd-none' : 'col-5'}`}><img className="w-75" src={imgTimeLapse.psMarzo24} alt="Entrada Park session Marzo 2024" /></div>
            <div className="col-2 d-flex align-items-center justify-content-center">
              <p className="text-white text-center fs-5 fw-bold fst-italic bg-black py-3">MARZO 2024</p>
            </div>
            <div className="col d-flex flex-column align-items-center justify-content-center">
            <div id="carouselControlsMarzo" className="carousel slide w-100" data-ride="carousel">
            <div className="carousel-inner rounded-1">
              {Array.from({ length: 19 }, (_, i) => `marzo24${String(i + 1).padStart(2, '0')}`).map((image, index) => (
                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={image}>
                  <img className="d-block w-100" src={imgTimeLapse[image]} alt={`Slide ${index + 1}`} />
                </div>
              ))}
            </div>

              <button className="carousel-control-prev" type="button" data-bs-target="#carouselControlsMarzo" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only"></span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselControlsMarzo" data-bs-slide="next">
                <span className="sr-only text-white"></span>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
              </button>
            </div>
            <button className="btn m_bg_primary text-white mt-2" onClick={()=>{marzo24 ? setMarzo24(false) : setMarzo24(true) }}>{marzo24 ? "Contraer" : "Ampliar"}</button> 
            </div>
            
          </div>

        </div>
    </div>
  )
}
{/* */}
const AdcMidTop =()=>{
  return(
    <div className="d-flex">
          <div className="w-50 d-flex flex-column align-items-center justify-content-center">
            <p className="text-white fs-5 fw-bold fst-italic">NOVIEMBRE 2024</p>
            <img className="w-50" src={imagenes.psNoviembre24} alt="Entrada Park session noviembre 2024" />
          </div>

          <div className="w-50">
            <div className="mx-5 d-flex flex-column align-items-center justify-content-center h-100">
            <p className="text-white fs-5 m_font_primary">.</p>
            <div id="carouselControls" className="carousel slide w-100" data-ride="carousel">
              <div className="carousel-inner rounded-1">
                <div className="carousel-item active">
                  <img className="d-block w-100" src={imagenes.ps01} alt="First slide" />
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100" src={imagenes.ps02} alt="Second slide" />
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100" src={imagenes.ps03} alt="Third slide" />
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100" src={imagenes.ps04} alt="second slide" />
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100" src={imagenes.ps05} alt="mengeche slide" />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselControls" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">=</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselControls" data-bs-slide="next">
                <span className="sr-only text-white">=</span>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
              </button>
            </div>
            </div>
            </div>
          </div>
  )
}