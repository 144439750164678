import { isMobile } from "react-device-detect"
import { imagenes } from "../asset/asset"
import { ButtonBlank } from "../componentes/buttons"
import { DekstopTimeLapse, TimeLapse } from "../componentes/TimeLapse"
import { useContext } from "react"
import { MyContext } from "../context/Context"

export const ParkSession =()=>{
  return(
      <>
        {isMobile ? <ParkSessionMobile/> : <PcParkSession  />}
      </>
  )
}
const ParkSessionMobile =()=>{
  return(
    <div className="m-0 p-0">
    <header className="w-100 m_h_100vh m_header_ps_mobile text-white">
      <div className="m_h_100vh w-100 d-flex flex-column justify-content-between align-items-center ">
        <div className="text-start w-100">
          <span className="m_font_primary fs-1 ms-5 fst-italic">-</span>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center w-100">
          <h2 translate="no" className="m_font_primary mb-3 text-center">PARK SESSION</h2>
          <p className="fs-1 fw-bold fst-italic pt-5 w-75 text-center">HASTA QUE EL UNDER SUENE EN LA LUNA</p>
        </div>
        <div className="">
          <img className="w-100" src={imagenes.psBGbot} alt="banner park Session" />
        </div>
      </div>  
    </header> 

    <div style={{ position: 'relative' }}>
      <img className="w-100" src={imagenes.bannerQuiebrePS} alt="" />
      <div className="border-0 rounded-2 py-1 px-2 fs-4 m_font_primary" style={{
          position: 'absolute',
          top: '65%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1, 
        }}>
        </div>
    </div>
    <div className="d-flex flex-column">
      <img className="w-100" src={imagenes.bannerQuiebrePStop} alt="" />
      <div className="w-100 d-flex jusify-content-between align-items-center justify-content-center align-items-center">
        <div className="w-75 d-flex justify-content-around align-items-center my-5 text-white flex-column">
        <div className="m_w_70 d-flex flex-column">
            <img className="w-100 mb-2 " src={imagenes.BannerPSizquierdo} alt="parkSession julio" />
            <ButtonBlank linkBlank="https://drive.google.com/drive/folders/13WK4njK17yGIJcaR4sHolg-BrYNwb8rH" textButton="Mirá las fotos de la Park session"  />
          </div>  
          <div className="m_w_70 d-flex flex-column mb-4">
            <img className="w-100 mb-2" src={imagenes.BannerPSderecho} alt="parkeandola" />
            <ButtonBlank linkBlank="https://drive.google.com/drive/folders/1Uqio-HULxYQ_QrJH0EOKWfX8SZzeemZa" textButton="Mirá las fotos de la session"  />
          </div>
             
        </div>
      </div>
    </div>
  </div>
  )
}
const PcParkSession =()=>{
  const {admLogeado} = useContext(MyContext);
  return(
    <div className="m-0 p-0">

      <div className="m_ps_stars">
        <header className="w-100 m_h_100vh header_ParkSession">
          <div className="m_luna_derechaTop">
            <div className="w-100 m_h_100vh m_ps_LunaIzquierda">
              <div className="m_ps_astronauta">
                <div className="w-100 m_h_100vh text-center d-flex flex-column justify-content-between align-items-center">
                  <span className="m_font_primary fs-1 ms-5 fst-italic">-</span>
                  <h2 translate="no" className="m_font_primary text-white mb-5">PARK SESSION</h2>
                  <p className="fs-2 fw-bold fst-italic text-white">HASTA QUE EL UNDER SUENE EN LA LUNA</p>
                  <img className="w-100" src={imagenes.quiebreEntradasParksessionTop} alt="banner park Session" />
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>

      <div style={{ position: 'relative' }}>
        <img style={{ width: '100%' }} src={imagenes.bannerQuiebrePS} alt="" />
        
        <div className="border-0 rounded-2 py-1 px-2 bg-transparent text-black" style={{
          position: 'absolute',
          top: '65%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1, 
        }}>
          {/* <ButtonBlank textButton="COMPRAR ENTRADA" linkBlank="https://alpogo.com/evento/perra-laika-rusa-y-living-viejo-en-park-session-17314"  /> */}
        </div>
      </div>
      <img className="w-100" src={imagenes.quiebreEntradasParksessionBot} alt="" />
      <div className="d-flex flex-column m_bg_stars d-none ">
        <div className="m_moon_left">
          <div className="m_bg_dark">
            <img className="w-100" src={imagenes.quiebreEntradasParksessionBot} alt="" />
            <div className="w-100 d-flex jusify-content-between align-items-center justify-content-center align-items-center ">
              <div className="w-75 d-flex justify-content-around my-5 text-white">
                <div className="m_w_30 d-flex flex-column">
                  <img className="w-100  mb-2 " src={imagenes.BannerPSizquierdo} alt="parkeandola" />
                  <ButtonBlank linkBlank="https://drive.google.com/drive/folders/13WK4njK17yGIJcaR4sHolg-BrYNwb8rH" textButton="Mirá las fotos de la session"  />  
                </div>
                <div className="m_w_30 d-flex flex-column">
                  <img className="w-100 mb-2" src={imagenes.BannerPSderecho} alt="parkSession julio" />
                  <ButtonBlank linkBlank="https://drive.google.com/drive/folders/1Uqio-HULxYQ_QrJH0EOKWfX8SZzeemZa" textButton="Mirá las fotos de la session"  />
                </div>

              </div>
            </div>  
          </div>
        </div>
      </div>
      <div className="">
        <DekstopTimeLapse />
      </div>
    </div>
  )
}